import React from 'react';
import PropTypes from 'prop-types';

if (process.env.CLIENT) {
  require('./Blocks.scss'); // eslint-disable-line global-require
}

const Blocks = ({ body }) => (
  <div className="blocks__content" dangerouslySetInnerHTML={{ __html: body }} />
);

Blocks.propTypes = {
  body: PropTypes.string.isRequired,
};

export default Blocks;

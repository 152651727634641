import React from 'react';
import PropTypes from 'prop-types';

import Collection from '../Collection';
import Promo from '../Promo';
import { promoShape } from '../shapes';

if (process.env.CLIENT) {
  require('./ArticlePrimaryCollection.scss'); // eslint-disable-line global-require
}

const ArticlePrimaryCollection = ({ id, title, promos, TitleElement }) => (
  <Collection
    collectionClassName="article-primary"
    id={id}
    TitleElement={TitleElement}
    title={title}
    titleClassName="article-primary__title"
  >
    {promos
      .slice(0, 16)
      .map(({ id: promoId, imageId, title: promoTitle, synopsis, subtitle, type, url }) => (
        <div key={promoId} className="gel-layout__item gel-1/2 gel-1/3@m gel-1/1@xl">
          <Promo
            collectionId={id}
            collectionTitle={title}
            id={promoId}
            imageId={imageId}
            synopsis={synopsis}
            subtitle={subtitle}
            title={promoTitle}
            type={type}
            url={url}
          />
        </div>
      ))}
  </Collection>
);

ArticlePrimaryCollection.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
  promos: PropTypes.arrayOf(PropTypes.shape(promoShape)).isRequired,
  TitleElement: PropTypes.node,
};

ArticlePrimaryCollection.defaultProps = {
  id: undefined,
  TitleElement: 'h2',
};

export default ArticlePrimaryCollection;

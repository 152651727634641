import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import classNames from 'classnames';
import Blocks from '../blocks/Blocks';
import ArticlePrimaryCollection from '../promo/collections/ArticlePrimaryCollection';
import ArticleSecondaryCollection from '../promo/collections/ArticleSecondaryCollection';
import MetaProperties from '../generic/MetaProperties';
import { promoShape } from '../promo/shapes';

export const BlocksErrorPage = loadable(() => import('../blocksErrorPage/Page'));

if (process.env.CLIENT) {
  require('./Page.scss'); // eslint-disable-line global-require
  require('./FAQs.scss'); // eslint-disable-line global-require
}

export const Page = ({ blocksFailed, ...props }) =>
  blocksFailed ? <BlocksErrorPage /> : <ArticlePage {...props} />;

Page.propTypes = {
  blocksFailed: PropTypes.bool,
};

Page.defaultProps = {
  blocksFailed: false,
};

const getMetaImage = (config, metaImage) =>
  metaImage
    ? `https://${metaImage.replace('$recipe', '1200xn')}`
    : `${config.cdnUrl}images/non-spriteable-images/bbc_placeholder.png`;

export const ArticlePage = ({
  config,
  location,
  blocksBody,
  metaDescription,
  metaImage,
  primaryCollection,
  secondaryCollection,
  title,
}) => {
  const metaImageURL = getMetaImage(config, metaImage);
  return (
    <>
      <MetaProperties
        title={title}
        description={metaDescription}
        imageUrl={metaImageURL}
        imageWidth="1200"
      />
      <div className="article-page__wrap">
        <div className="gel-wrap">
          <div
            className={classNames('gel-layout', {
              'faqs-page':
                location.pathname === '/food/faqs' || location.pathname === '/food/preview/faqs',
            })}
          >
            <div className="food-content__left gel-layout__item gel-1/1 gel-2/3@xl">
              <div className="blocks__container">
                <Blocks body={blocksBody} />
              </div>
              <div className="secondary-promo__container">
                {secondaryCollection && secondaryCollection.collection && (
                  <ArticleSecondaryCollection
                    title={
                      secondaryCollection.collectionTitle || secondaryCollection.collection.title
                    }
                    promos={secondaryCollection.collection.promos}
                  />
                )}
              </div>
            </div>
            <div className="food-content__right gel-layout__item gel-1/1 gel-1/3@xl">
              <div className="primary-promo__container">
                {primaryCollection && primaryCollection.collection && (
                  <ArticlePrimaryCollection
                    title={primaryCollection.collectionTitle || primaryCollection.collection.title}
                    promos={primaryCollection.collection.promos}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

ArticlePage.propTypes = {
  config: PropTypes.shape({
    cdnUrl: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  metaDescription: PropTypes.string.isRequired,
  metaImage: PropTypes.string,
  blocksBody: PropTypes.string.isRequired,
  primaryCollection: PropTypes.shape({
    collectionTitle: PropTypes.string,
    collection: PropTypes.shape({
      title: PropTypes.string.isRequired,
      promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
    }),
  }),
  secondaryCollection: PropTypes.shape({
    collectionTitle: PropTypes.string,
    collection: PropTypes.shape({
      title: PropTypes.string.isRequired,
      promos: PropTypes.arrayOf(PropTypes.shape(promoShape)),
    }),
  }),
};

ArticlePage.defaultProps = {
  metaImage: undefined,
  primaryCollection: undefined,
  secondaryCollection: undefined,
};

export default connect(state => ({
  blocksBody: state.articlePageReducer.blocksBody,
  blocksFailed: state.articlePageReducer.blocksFailed,
  config: state.pageReducer,
  title: state.articlePageReducer.title,
  metaDescription: state.articlePageReducer.metaDescription,
  metaImage: state.articlePageReducer.metaImage,
  primaryCollection: state.articlePageReducer.primaryCollection,
  secondaryCollection: state.articlePageReducer.secondaryCollection,
}))(Page);
